import "./App.css";
import { useRef, useState } from "react";
const openai = require("openai");

function Page4() {
  const inputAPIKey = useRef();
  const inputBlogTitles = useRef();

  const [apiKeyError, setApiKeyError] = useState("");
  const [BlogTitlesError, setNoBlogTitlesError] = useState("");

  const [loading, setLoading] = useState(false);

  const storedData = localStorage.getItem("integrationData");
  const integrationData = storedData ? JSON.parse(storedData) : null;
  const wordpressURL = integrationData ? integrationData.wordpressURL : "";
  const username = integrationData ? integrationData.username : "";
  const password = integrationData ? integrationData.password : "";

  async function handleSend() {
    const apiKey = inputAPIKey.current.value;
    const blogTitles = inputBlogTitles.current.value
      .split(",")
      .map((s) => s.trim());

    setLoading(true);

    if (!apiKey) {
      setApiKeyError("Please input apiKey");
      setLoading(false);
      return;
    } else {
      setApiKeyError("");
    }

    if (!blogTitles) {
      setNoBlogTitlesError("Please input blog titles");
      setLoading(false);
      return;
    } else {
      setNoBlogTitlesError("");
    }

    var jsonData = {
      blog_titles: [],
    };
    generateData();
    async function generateData() {
      // Defining the API key
      const key = apiKey;

      async function blogTitleGen() {
        for (const blogTitle of blogTitles) {
          jsonData.blog_titles.push({
            name: blogTitle,
            blog_content: "",
          });
        }

        blogContent();
      }

      async function blogContent() {
        for (var k = 0; k < blogTitles.length; k++) {
          const DEFAULT_PARAMS = {
            model: "gpt-3.5-turbo-1106",
            messages: [
              {
                role: "system",
                content:
                  "You are ChatGPT, a large language model trained by OpenAI.",
              },
              {
                role: "user",

                content: `Please ignore all previous instructions. 
                Use <h1> tag for title or main heading.
                Use <h2> tag for sub heading. Use <p> tag for paragraphs. Use <li> </li> tags for bullet form content. Directly start from <h1> tag

                Write me an article with at least 2000 words on ${jsonData.blog_titles[k].name}. It's important that the article has at-least 2000 words, so please respect it. I want you to act as a very proficient SEO and high quality informative content writer that speaks and writers fluent English.

                    The article has to be well structured, with the following components written in order from 1 to 7.
                    
                    1. A single H1. This H1 which should be formatted with the opening <h1> tag and the closing </h1> tag.
                    2. An introduction between 200 and 250 words including the Topic "${jsonData.blog_titles[k].name}".
                    3. A table of content. The table of content should be a list of bullet points of the main <h2> headings of the article, and all the content of table should be bookmarked / hyperlinked with the respective H2.
                    4. Several H2s with related keywords to the topic "${jsonData.blog_titles[k].name}". Each H2 should be formatted with the opening <h2> tag and the closing </h2> tag.
                    5. The text in all sections should provide value and include relevant LSI keywords and All text should be formatted with the <p> tag.
                    6. A section of questions and answers addressing the main questions people have about ${jsonData.blog_titles[k].name}. atleast 5 Questions and Answers, These questions should not have been answered previously in the article. Each question should be in H3 Heading, that is Each H3 should be formatted with the opening <h3> tag and the closing </h3> tag.
                    7. A conclusion with Heading "Summary of the Blog" summarizing the whole article including ${jsonData.blog_titles[k].name}.`,
              },
            ],
          };

          const params = { ...DEFAULT_PARAMS };
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + key,
            },
            body: JSON.stringify(params),
          };
          const response = await fetch(
            "https://api.openai.com/v1/chat/completions",
            requestOptions
          );
          const data = await response.json();
          const content = data.choices[0].message.content;
          console.log(content);

          var blogcontents = content;

          jsonData.blog_titles[k].blog_content = blogcontents;
        }

        writeToFile(jsonData);
        // setLoading(false);
      }
      blogTitleGen();
    }
  }

  async function writeToFile(data) {
    try {
      const jsonString = JSON.stringify(data, null, 2);

      // Parse the JSON string into an object
      const parsedData = JSON.parse(jsonString);

      // Loop through each item in the blog_titles array
      for (const blog of parsedData.blog_titles) {
        // Extract the name and blog_content for each blog
        const blogTitle = blog.name;
        const description = blog.blog_content;
        const openAIKey = inputAPIKey.current.value;
        console.log("shh");
        try {
          const response = await fetch("http://localhost:3000/post", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              openAIKey,
              wordpressURL,
              username,
              password,
              blogTitle,
              description,
            }),
          });

          const data = await response.json();
          console.log(data);
          setLoading(false);
        } catch (error) {
          console.log(`Error: ${error.message}`);
          setLoading(false);
        }
      }

      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data.json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (e) {
      console.log("Error" + e.message);
    }
  }

  return (
    <>
      <div class="container mt-4">
        <div class="row">
          <div class="col-10 mx-auto">
            <h2 id="intro" style={{ textAlign: "center" }}>
              AI Article Generator
            </h2>
          </div>
        </div>
      </div>

      <div class="container mt-4">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-6 col-12">
            <div class="form-group mx-auto">
              <label>
                API Key*
                {apiKeyError && (
                  <span className="validation-error">{apiKeyError}</span>
                )}
              </label>
              <input
                type="password"
                ref={inputAPIKey}
                class="form-control col"
                placeholder=""
                required
              />
            </div>

            <div class="form-group mx-auto">
              <label>
                Blog Titles (comma-separated)*
                {BlogTitlesError && (
                  <span className="validation-error">{BlogTitlesError}</span>
                )}
              </label>
              <input
                type="text"
                ref={inputBlogTitles}
                class="form-control col"
                placeholder=""
                required
              />
            </div>

            <div class="row mt-4">
              <div class="col-12 text-center mx-auto">
                <button
                  disabled={loading}
                  className="btn btn-primary flex justify-center items-center"
                  onClick={handleSend}
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="animate-spin ml-3 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  ) : (
                    "Generate"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page4;
