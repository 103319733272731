// Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState("");

  const handleSelectChange = (event) => {
    const page = event.target.value;
    setSelectedPage(page);
    navigate(page); // Navigate to the selected page
  };

  const Integration = () => {
    const storedData = localStorage.getItem("integrationData");
    const integrationData = storedData ? JSON.parse(storedData) : null;

    const [wordpressURL, setWordpressURL] = useState(
      integrationData ? integrationData.wordpressURL : ""
    );
    const [username, setUsername] = useState(
      integrationData ? integrationData.username : ""
    );
    const [password, setPassword] = useState(
      integrationData ? integrationData.password : ""
    );

    const [errors, setErrors] = useState({
      wordpressURL: false,
      username: false,
      password: false,
    });

    const handleSave = async () => {
      let formIsValid = true;
      const requiredFields = {
        wordpressURL,
        username,
        password,
      };
      const newErrors = { ...errors };

      Object.keys(requiredFields).forEach((field) => {
        if (!requiredFields[field]) {
          formIsValid = false;
          newErrors[field] = true;
        } else {
          newErrors[field] = false;
        }
      });

      setErrors(newErrors);

      if (formIsValid) {
        try {
          const integrationData = JSON.stringify({
            wordpressURL,
            username,
            password,
          });
          localStorage.setItem("integrationData", integrationData);

          // Close the modal by setting its state to false
          setIsModalOpen(false);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    return (
      <div className="space-y-4">
        <div>
          <label className="text-gray-600 font-semibold block mb-2">
            Wordpress URL{" "}
            {errors.wordpressURL && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <input
            value={wordpressURL}
            onChange={(e) => {
              setWordpressURL(e.target.value);
              setErrors({ ...errors, wordpressURL: false });
            }}
            className={`border ${
              errors.wordpressURL ? "border-red-500" : "border-gray-300"
            } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
            id="wordpressURL"
            type="text"
            placeholder="https://mywebsite.com"
          />
        </div>

        <div>
          <label className="text-gray-600 font-semibold block mb-2">
            User Name{" "}
            {errors.username && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <input
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setErrors({ ...errors, username: false });
            }}
            className={`border ${
              errors.username ? "border-red-500" : "border-gray-300"
            } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
            id="username"
            type="text"
            placeholder="admin@email.com"
          />
        </div>

        <div>
          <label className="text-gray-600 font-semibold block mb-2">
            Application Password{" "}
            {errors.password && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrors({ ...errors, password: false });
            }}
            className={`border ${
              errors.password ? "border-red-500" : "border-gray-300"
            } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
            id="password"
            type="text"
            placeholder="Pmax123@1*3"
          />
        </div>

        <div className="flex justify-end">
          <button
            className="mt-6 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            onClick={handleSave}
          >
            {"Save"}
          </button>
        </div>
      </div>
    );
  };

  // State for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="bg-gray-700 text-white px-4 py-2 flex justify-between">
      <select
        value={selectedPage}
        onChange={handleSelectChange}
        className="text-sm px-4 py-2 text-black outline:none focus:outline-none rounded-sm"
      >
        <option value="/">Home</option>
        <option value="/page1">Page 1</option>
        <option value="/page2">Page 2</option>
        <option value="/page3">Page 3</option>
        <option value="/page4">Page 4</option>
      </select>

      <button
        onClick={toggleModal}
        className="text-sm bg-white text-black rounded-sm outline:none focus:outline-none px-4 py-2"
      >
        Configure
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-4 border h-full max-h-[520px] w-full max-w-[600px] shadow-lg rounded-md bg-white">
            <div className="">
              <div className="text-center py-2 mx-auto flex items-center justify-center rounded-md bg-green-100">
                {/* Modal Header */}
                <h3 className="text-lg font-medium text-gray-900">
                  Integration Configuration
                </h3>
              </div>
              <div className="mt-2 px-7 py-3">
                {/* Integration Component Inside Modal */}
                <Integration />
              </div>
              <div className="items-center px-4 py-3">
                <button
                  onClick={toggleModal}
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
