import "./App.css";
import { useRef, useState } from "react";
const openai = require("openai");

function Page1() {
  const inputAPIKey = useRef();
  const inputMainTopic = useRef();
  const inputNoSubTopics = useRef();
  const inputNoKeywords = useRef();
  const inputNoBlogTitles = useRef();

  const [apiKeyError, setApiKeyError] = useState("");
  const [mainTopicError, setMainTopicError] = useState("");
  const [noSubTopicsError, setNoSubTopicsError] = useState("");
  const [noKeywordsError, setNoKeywordsError] = useState("");
  const [noBlogTitlesError, setNoBlogTitlesError] = useState("");

  const [loading, setLoading] = useState(false);

  async function handleSend() {
    const apiKey = inputAPIKey.current.value;
    const mainTopic = inputMainTopic.current.value;
    const noSubTopics = inputNoSubTopics.current.value;
    const noKeywords = inputNoKeywords.current.value;
    const noBlogTitles = inputNoBlogTitles.current.value;
    setLoading(true);

    if (!apiKey) {
      setApiKeyError("Please input apiKey");
      setLoading(false);
      return;
    } else {
      setApiKeyError("");
    }

    if (!mainTopic) {
      setMainTopicError("Please input mainTopic");
      setLoading(false);
      return;
    } else {
      setMainTopicError("");
    }

    if (!noSubTopics) {
      setNoSubTopicsError("Please input noSubTopics");
      setLoading(false);
      return;
    } else {
      setNoSubTopicsError("");
    }

    if (!noKeywords) {
      setNoKeywordsError("Please input noKeywords");
      setLoading(false);
      return;
    } else {
      setNoKeywordsError("");
    }

    if (!noBlogTitles) {
      setNoBlogTitlesError("Please input noBlogTitles");
      setLoading(false);
      return;
    } else {
      setNoBlogTitlesError("");
    }

    var jsonData = {
      main_topic: {
        name: mainTopic,
        sub_topics: [],
      },
    };
    generateData();
    async function generateData() {
      var subtopics = [];
      // Define the number of sub topics, keywords, and blog titles
      var numSubTopics = noSubTopics;
      var numKeywords = noKeywords;
      var numBlogTitles = noBlogTitles;

      // Defining the API key
      const key = apiKey;

      async function subTopics() {
        const DEFAULT_PARAMS = {
          model: "gpt-3.5-turbo-1106",
          messages: [
            {
              role: "system",
              content:
                "You are ChatGPT, a large language model trained by OpenAI.",
            },
            {
              role: "user",
              content: `Consider ${jsonData.main_topic.name} as a Main Niche / Category, Now please Suggest ${numSubTopics} Micro Niches or sub-categories name only for the main niche which is ${jsonData.main_topic.name}.`,
            },
          ],
        };

        const params = { ...DEFAULT_PARAMS };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + key,
          },
          body: JSON.stringify(params),
        };
        const response = await fetch(
          "https://api.openai.com/v1/chat/completions",
          requestOptions
        );
        const data = await response.json();
        const content = data.choices[0].message.content;

        if (content.includes("\n")) {
          subtopics = content
            .split("\n")
            .filter((line) => line.match(/^\d+\. /) || line === "1")
            .map((line) => line.replace(/^\d+\. /, ""));
        } else {
          subtopics[0] = content.replace(/^\d+\.\s*/, "");
        }
        console.log("Sub topics" + subtopics);
        if (subtopics.length > 0) {
          for (var i = 0; i < numSubTopics; i++) {
            var subTopic = {
              name: subtopics[i],
              keywords: [],
            };
            jsonData.main_topic.sub_topics.push(subTopic);
          }
        }

        keywords();
      }

      async function keywords() {
        var keywords = [];
        for (var i = 0; i < numSubTopics; i++) {
          const DEFAULT_PARAMS = {
            model: "gpt-3.5-turbo-1106",
            messages: [
              {
                role: "system",
                content:
                  "You are ChatGPT, a large language model trained by OpenAI.",
              },
              {
                role: "user",
                content: `Generate ${numKeywords} highly related and most common keywords for the micro niche: ${subtopics[i]}`,
              },
            ],
          };

          const params = { ...DEFAULT_PARAMS };
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + key,
            },
            body: JSON.stringify(params),
          };
          const response = await fetch(
            "https://api.openai.com/v1/chat/completions",
            requestOptions
          );
          const data = await response.json();
          const content = data.choices[0].message.content;

          console.log("Keywords" + content);

          if (content.includes("\n")) {
            keywords = content
              .split("\n")
              .filter((line) => line.match(/^\d+\. /) || line === "1")
              .map((line) => line.replace(/^\d+\. /, ""));
          } else {
            keywords[0] = content.replace(/^\d+\.\s*/, "");
          }

          for (var j = 0; j < numKeywords; j++) {
            var keyword = {
              name: keywords[j],
              blog_titles: [],
            };
            jsonData.main_topic.sub_topics[i].keywords.push(keyword);
          }
        }
        blogTitle();
      }

      async function blogTitle() {
        var blogtitles = [];
        for (var i = 0; i < numSubTopics; i++) {
          for (var j = 0; j < numKeywords; j++) {
            const DEFAULT_PARAMS = {
              model: "gpt-3.5-turbo-1106",
              messages: [
                {
                  role: "system",
                  content:
                    "You are ChatGPT, a large language model trained by OpenAI.",
                },
                {
                  role: "user",
                  content: `Generate ${numBlogTitles} Informative Style blog titles for the keyword: ${jsonData.main_topic.sub_topics[i].keywords[j].name}`,
                },
              ],
            };

            const params = { ...DEFAULT_PARAMS };
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + key,
              },
              body: JSON.stringify(params),
            };
            const response = await fetch(
              "https://api.openai.com/v1/chat/completions",
              requestOptions
            );
            const data = await response.json();
            const content = data.choices[0].message.content;

            console.log("Blog titles" + content);

            if (content.includes("\n")) {
              blogtitles = content
                .split("\n")
                .filter((line) => line.match(/^\d+\. /) || line === "1")
                .map((line) => line.replace(/^\d+\. /, ""));
            } else {
              blogtitles[0] = content.replace(/^\d+\.\s*/, "");
            }

            for (var k = 0; k < numBlogTitles; k++) {
              var blogtitle = {
                name: blogtitles[k],
                blog_content: [],
              };
              jsonData.main_topic.sub_topics[i].keywords[j].blog_titles.push(
                blogtitle
              );
            }
          }
        }
        blogContent();
      }

      async function blogContent() {
        for (var i = 0; i < numSubTopics; i++) {
          for (var j = 0; j < numKeywords; j++) {
            for (var k = 0; k < numBlogTitles; k++) {
              const DEFAULT_PARAMS = {
                model: "gpt-3.5-turbo-1106",
                messages: [
                  {
                    role: "system",
                    content:
                      "You are ChatGPT, a large language model trained by OpenAI.",
                  },
                  {
                    role: "user",
                    content: `Write blog post for title ${jsonData.main_topic.sub_topics[i].keywords[j].blog_titles[k].name}. Use <h1> tag for title or main heading.
Use <h2> tag for sub heading. Use <p> tag for paragraphs. Use <li> </li> tags for bullet form content. Directly start from <h1> tag`,
                    // content: `Please ignore all previous instructions. Write me an article with at least 2000 words on ${jsonData.main_topic.sub_topics[i].keywords[j].blog_titles[k].name}. It's important that the article has at-least 2000 words, so please respect it. I want you to act as a very proficient SEO and high quality informative content writer that speaks and writers fluent English.

                    // The article has to be well structured and follow the below structure.

                    // 1. A single H1. This H1 which should be formatted with the opening <h1> tag and the closing </h1> tag.
                    // 2. An introduction between 200 and 250 words including the Topic "${jsonData.main_topic.sub_topics[i].keywords[j].blog_titles[k].name}".
                    // 3. A table of content. The table of content should be a list of bullet points of the main <h2> headings of the article, and all the content of table should be bookmarked / hyperlinked with the respective H2.
                    // 4. Several H2s with related keywords to the topic "${jsonData.main_topic.sub_topics[i].keywords[j].blog_titles[k].name}". Each H2 should be formatted with the opening <h2> tag and the closing </h2> tag.
                    // 5. The text in all sections should provide value and include relevant LSI keywords and All text should be formatted with the <p> tag.
                    // 6. A section of questions and answers addressing the main questions people have about ${jsonData.main_topic.sub_topics[i].keywords[j].blog_titles[k].name}. atleast 5 Questions and Answers, These questions should not have been answered previously in the article. Each question should be in H3 Heading, that is Each H3 should be formatted with the opening <h3> tag and the closing </h3> tag.
                    // 7. A conclusion with Heading "Summary of the Blog" summarizing the whole article including ${jsonData.main_topic.sub_topics[i].keywords[j].blog_titles[k].name}.`,
                  },
                ],
              };

              const params = { ...DEFAULT_PARAMS };
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + key,
                },
                body: JSON.stringify(params),
              };
              const response = await fetch(
                "https://api.openai.com/v1/chat/completions",
                requestOptions
              );
              const data = await response.json();
              const content = data.choices[0].message.content;
              console.log(content);

              var blogcontents = content;

              jsonData.main_topic.sub_topics[i].keywords[j].blog_titles[
                k
              ].blog_content = blogcontents;
            }
          }
        }
        writeToFile(jsonData);
        setLoading(false);
      }
      subTopics();
    }
  }

  function writeToFile(data) {
    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  return (
    <>
      <div class="container mt-4">
        <div class="row">
          <div class="col-10 mx-auto">
            <h2 id="intro" style={{ textAlign: "center" }}>
              AI Article Generator
            </h2>
          </div>
        </div>
      </div>

      <div class="container mt-4">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-6 col-12">
            <div class="form-group mx-auto">
              <label>
                API Key*
                {apiKeyError && (
                  <span className="validation-error">{apiKeyError}</span>
                )}
              </label>
              <input
                type="password"
                ref={inputAPIKey}
                class="form-control col"
                placeholder=""
                required
              />
            </div>

            <div class="form-group mx-auto">
              <label>
                Main topic*
                {mainTopicError && (
                  <span className="validation-error">{mainTopicError}</span>
                )}
              </label>
              <input
                type="text"
                ref={inputMainTopic}
                class="form-control col"
                placeholder=""
                required
              />
            </div>

            <div class="row mt-4">
              <div class="col-12 text-center mx-auto">
                <button
                  disabled={loading}
                  class="btn btn-primary"
                  onClick={handleSend}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 col-12">
            <div class="form-group mx-auto">
              <label>
                No of Sub Topics*
                {noSubTopicsError && (
                  <span className="validation-error">{noSubTopicsError}</span>
                )}
              </label>
              <input
                type="number"
                ref={inputNoSubTopics}
                class="form-control col"
                placeholder=""
                required
              />
            </div>

            <div class="form-group mx-auto">
              <label>
                No of Keywords*
                {noKeywordsError && (
                  <span className="validation-error">{noKeywordsError}</span>
                )}
              </label>
              <input
                type="number"
                ref={inputNoKeywords}
                class="form-control col"
                placeholder=""
                required
              />
            </div>

            <div class="form-group mx-auto">
              <label>
                No of Blog Titles*
                {noBlogTitlesError && (
                  <span className="validation-error">{noBlogTitlesError}</span>
                )}
              </label>
              <input
                type="number"
                ref={inputNoBlogTitles}
                class="form-control col"
                placeholder=""
                required
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page1;
